import React,{useState,useEffect} from 'react'
import ModalInformativo from '../generales/ModalInformativo'
import {Table,TableBody,TableCell,TableContainer,TableHead,TableRow
        ,IconButton,Tooltip,Box, Paper} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {exportarArch} from '../funciones/ListaExcel'
import SaveIcon from '@material-ui/icons/Save';
import moment from 'moment';
const useStyles = makeStyles({
    root: {
      width: '100%',
    },
    formControl:{
        boxSizing:'border-box',
        fontSize:'1rem',
        position:'relative',
        marginBottom:'1em'
    },
    container: {
      width: '18rem',
      //minHeight: 280,
      //minWidth:'100%',
    },
    tablaContainer: {
        width: '50rem',
        maxHeight: window.innerHeight-200,
        minHeight: 280,
        //minWidth:'100%',
      },
    totalEstilo:{
        fontSize:'1.25rem',
        fontWeight:'bold'
    },
    tituloEstilo:{
        paddingBottom:'.25rem',
        fontWeight:'bold'
    },
    tableRow: {
      "&$selected, &$selected:hover": {
        backgroundColor: process.env.REACT_APP_Color_Second
      }
    },
    tableCell: {
      "$selected &": {
        color: "white"
      }
    },
    hover: {},
    selected: {}
  });

  
const ModalContactos = ({modalAbierto, setModalAbierto,list}) => {
    const classes = useStyles();
    const [idSelec,setIdSelec] = useState(-1)
    const [listaExcel, setListaExcel] = React.useState([]);
    useEffect(() => {
        //console.log(list);
        let arrM=[]
        arrM.push(["#","Teléfonos","Fecha Recibido","Nombre","Mensaje"])        
        list.Contactos.forEach((aux,index)=>{
            arrM.push([index+1,aux.cel,moment(aux.fch).format("DD/MM/YYYY HH:mm:ss"),aux.celNom,aux.msg,                ])
        })
        setListaExcel(arrM)
    }, [list])
    
    const RenglonNum =()=>{                
    }
    const celda=(alinear,estilo,contenido)=>{
        return (
        <TableCell align={alinear} className={classes.tableCell} 
            padding={'none'} style={estilo} >
          {contenido}
        </TableCell>
        )
    }

    function TablaPromo(auxDatos) {
        let numRenglon=0;
        let estiloGen={backgroundColor:process.env.REACT_APP_Fondo_Color,color:'white'}
        return(
        <Paper className={classes.table} >
        <TableContainer className={classes.tablaContainer} id="tableAcentuacion" >
            <Table stickyHeader aria-label="sticky table">
                <TableHead >    
                <TableRow>       
                    { celda("center",estiloGen,`${auxDatos.length} Teléfonos`) }
                    { celda("center",estiloGen,"Fecha Recibido") }                         
                    { celda("center",estiloGen,"Nombre") }  
                    { celda("center",estiloGen,"Mensaje") }                      
                </TableRow>                        
                </TableHead>  
                <TableBody>                                  
                {auxDatos.map((tel) => {                                                                        
                    return (                  
                    <TableRow className={classes.tableRow} key={numRenglon} 
                        selected={ idSelec === tel.cel} onClick={RenglonNum}  
                        classes={{ hover: classes.hover, selected: classes.selected }}                         
                    >                     
                        { celda("center",[],tel.cel) } 
                        { celda("center",{width:10+'rem'},moment(tel.fch).format("DD/MM/YYYY HH:mm:ss")) }                        
                        { celda("left",[],tel.celNom) }   
                        { celda("left",[],tel.msg) }  
                    </TableRow>              
                    );                        
                    })
                }                                                
                </TableBody>
            </Table>
        </TableContainer>
        </Paper>
        ); 
    }

    return (
    <ModalInformativo
        titulo="Contactos"    tamanio="lg"    
        modalAbierto={modalAbierto} maxTam={true}
        setModalAbierto={setModalAbierto}        
    >
        {listaExcel.length!==0?
        <IconButton onClick={()=>exportarArch(listaExcel," Contactos de Difusión")} size="small" >
            <Tooltip title="Exportar a Excel">
              <SaveIcon  color="primary" size="small" />
            </Tooltip>
        </IconButton>
        :null}                          
        {list.Contactos && list.Contactos.length ? TablaPromo(list.Contactos):null}
    </ModalInformativo>
  )
}

export default ModalContactos