import React from 'react'
import { makeStyles,Drawer, Divider,IconButton,
        CardMedia ,Box, Typography,Card} from '@material-ui/core'
import Listas from './Listas';
import MenuIcon from '@material-ui/icons/Menu'; 
const drawerWidth = 240;

const estilos = makeStyles(theme=>({
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
      },
      barra:{
        flexGrow:2
    },
    menuButton: {
        marginRight: theme.spacing(2),
        
      },
    media: {
        height: 152,
        width: 220,
        borderRadius: '10px',
      },
    eltoolbar:theme.mixins.toolbar,
    drawerPaper: {
    width: drawerWidth,
    background:process.env.REACT_APP_Fondo_Color,
    color:'white'
    },
}))
const Cajon = (propiedades) => {
    const misclases =estilos()
    return (
        <Drawer 
            className={misclases.drawer} variant={propiedades.variant}
            open={propiedades.open} onClose={propiedades.onClose ? propiedades.onClose:null }
            classes={ { paper: misclases.drawerPaper, } } anchor="left"
        >
            <Box className={misclases.eltoolbar} textAlign="center" pt={2} >
            
                <Typography color="inherit"  
                    variant = { propiedades.usuario.Nombre.length > 15 ? "p" : "h5" } 
                >
                    <IconButton color="inherit"   onClick={()=>propiedades.funcionAbrir()} 
                    className={misclases.menuButton} aria-label="menu" size='small'
                >
                <MenuIcon/>
            </IconButton>
                    { propiedades.usuario.Nombre.toUpperCase() }                
                </Typography>
            </Box>
        
            <Divider/>
            <Listas className={misclases.barra} funcionAbrir={propiedades.funcionAbrir} />
            {/* <Box display="flex" mt={21}  px={1} mb={2} alignItems="flex-end"  alignContent="flex-end">
                <Card  className={misclases.media}>
                    <CardMedia    style={{width:11+'rem',marginLeft:1+'rem',marginTop:.5+'rem'}}                             
                        component="img" alt="Logo Neutro"
                        scaleType="fitCenter" image="/DADUVA_cuadrado.svg" image="/LOGORV2.svg" 
                        title="Logo Neutro"
                    />
                </Card>
            </Box>    */}     
    </Drawer>
    )
}

export default Cajon
