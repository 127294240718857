import {useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import EditarContacto from "./EditarContacto";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "block",
        width: 25 + 'rem',
        '& .MuiInputBase-input': {
            margin: theme.spacing(5),
        },
    },
    tableRow: {
        backgroundColor: process.env.REACT_APP_Fondo_Color,
    },
    alturaTabla: {
        minHeight: "420px",
        maxHeight: '86vh',
        overflowY: 'auto'
    },
    tableCellHeader: {
        width: "auto",
        backgroundColor: process.env.REACT_APP_Fondo_Color,
        color: "white",
    },
    tableCellSeleccionada: {
        color: "white",
    },
}));

const columnas = [
    {
        id: 1,
        titulo: "Fecha",
    },
    {
        id: 2,
        titulo: "Teléfono",
    },
    {
        id: 3,
        titulo: "Alias",
    },
];

export default function TablaDifusion({
                                          difusionDetail,
                                          solucionId,
                                          DeviceId,
                                          history,
                                          UserId
                                      }) {
    const classes = useStyles();

    const [idBotonEditar, setIdBotonEditar] = useState(-1);
    const [modalEditarAbierto, setModalEditarAbierto] = useState(false);
    const [modalTitulo, setModalTitulo] = useState("");
    const [sinDatos, guardarSinDatos] = useState(true);

    const [difusionSelected, setDifusionSelected] = useState(null);

    const modalEditarDifusion = modalEditarAbierto ? (
        <EditarContacto
            modalAbierto={modalEditarAbierto}
            setModalAbierto={setModalEditarAbierto}
            titulo={modalTitulo}
            UserId={UserId}
            DeviceId={DeviceId}
            history={history}
            difusionSelected={difusionSelected}
            difusionDetail={difusionDetail}
        />
    ) : null;

    function abrirModalEdit(row) {
        setModalEditarAbierto(!modalEditarAbierto);
        setModalTitulo("Editar alias de" + row.Cel);
        setDifusionSelected(row);
    }

    function handleClickEditar(row) {
        if (row.Cel !== idBotonEditar) {
            setIdBotonEditar(row.Cel);
        }
    }
    const celda = (alinear, estilo, contenido) => {
        return (
          <TableCell
            align={alinear}
            className={classes.tableCell}
            padding={"none"}
            style={estilo}
          >
            {contenido}
          </TableCell>
        );
    };

    const celdaSel = (alinear, estilo, contenido,claseCelda) => {
        return (
          <TableCell
            align={alinear}
            className={claseCelda}
            padding={"none"}
            style={estilo}
          >
            {contenido}
          </TableCell>
        );
    };

    function tablaCompleta(auxlista) {        
        return (
            <TableContainer className={classes.alturaTabla}>
                <Table className={classes.table} size="small" aria-label="resultados" stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell padding={"none"} align="center" className={classes.tableCellHeader}></TableCell>
                            {columnas.map((row) => {
                                return (
                                    <TableCell key={row.id} padding={"none"} align="center"
                                               className={classes.tableCellHeader}>
                                        {row.titulo}
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {auxlista.map((row, index) => {
                            let claseCelda =row.Cel === idBotonEditar ? classes.tableCellSeleccionada:classes.tableCell
                            return row.Status === 1 ?
                                <TableRow
                                    key={index}
                                    onClick={() => handleClickEditar(row)}
                                    className={row.Cel === idBotonEditar ? classes.tableRow : ""}
                                >
                                    <TableCell
                                        align="center"
                                        padding={"none"} style={{width: 30 + 'px'}}
                                        className={classes.TableCell}
                                    >
                                        <IconButton
                                            size="small"
                                            component={"span"}
                                            onClick={row.Cel === idBotonEditar ? () => abrirModalEdit(row) : null}
                                        >
                                            {row.Cel === idBotonEditar ? (
                                                <EditIcon style={{color: "white"}}/>
                                            ) : null}
                                        </IconButton>
                                    </TableCell>                                                                                                                  
                                    {celdaSel("center", {width: 40 + 'px'}, moment.utc(row.Fecha).format("DD/MM/YYYY"),claseCelda) }
                                    {celdaSel("center", [], row.Cel,claseCelda) }
                                    {celdaSel("left", [], row.Alias,claseCelda) }
                                </TableRow>
                            : null}
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    }

    const tablaSinDatos = () => {
        return (
            <TableContainer>
                <Table
                    className={classes.table}
                    size="small"
                    aria-label="resultados"
                    stickyHeader
                >
                    <TableHead>
                        <TableRow>
                            {columnas.map((row) => (
                                <TableCell
                                    key={row.id}
                                    padding={"none"}
                                    align="center"
                                    className={classes.tableCellHeader}
                                >
                                    {row.titulo}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell align="center" padding={"none"} style={{width: 95}}>
                                Lista
                            </TableCell>
                            <TableCell align="center" padding={"none"} style={{width: 95}}>
                                sin
                            </TableCell>
                            <TableCell align="center" padding={"none"} style={{width: 95}}>
                                contactos
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        );
    };

    const tablaSinResultados = () => {
        return (
            <TableContainer>
                <Table
                    className={classes.table}
                    size="small"
                    aria-label="resultados"
                    stickyHeader
                >
                    <TableHead>
                        <TableRow>
                            {columnas.map((row) => (
                                <TableCell
                                    key={row.id}
                                    padding={"none"}
                                    align="center"
                                    className={classes.tableCellHeader}
                                >
                                    {row.titulo}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell align="center" padding={"none"} style={{width: 95}}>
                                Sin
                            </TableCell>
                            <TableCell align="center" padding={"none"} style={{width: 95}}>
                                coincidencias...
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        );
    };

    return (
        <Paper className={classes.root}>
            {difusionDetail.length >= 1 ? tablaCompleta(difusionDetail) : tablaSinDatos()}
            {modalEditarDifusion}
        </Paper>
    );
}
